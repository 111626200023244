
import ApiService from "@/core/services/api.service";


export const SET_LISTA_EVENTO = "setListeventoEvento";
export const SET_LISTA_EVENTO_REV = "setListEventoRev";
export const SET_EVENTO = "setListEventoRev";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'




const actions = {
    //evento
    async create_evento(context, value) {

        await ApiService.post('evento', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },



    async create_evento_site(context, value) {

        let message = await ApiService.post('evento/produtor', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
        context.commit(SET_EVENTO, message.message.conteudo)
    },
    async update_evento_site(context, value) {

        let message = await ApiService.put('evento/produtor/'+ value[0].id, value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
        context.commit(SET_EVENTO, message.message.conteudo)
    },


    async update_evento(context, value) {

        await ApiService.put('evento/'+value.id,value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_eventos(context) {
        await ApiService.get('evento')
            .then(response => {
                context.commit(SET_LISTA_EVENTO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_evento(context,id) {
        await ApiService.get('evento/produtor/'+id)
            .then(response => {
                context.commit(SET_EVENTO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async set_arquivo(context,value) {
        await ApiService.get('evento/'+value)
            .then(response => {
                context.commit(SET_EVENTO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async delete_evento(context, value) {


        await ApiService.delete("evento/" + value.id)
          .then((response) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "error",
              message: error.response.data,
            })
          );
    
    
   
        context.commit(SET_LISTA_EVENTO_REV, value)
    
    
      },

      set_rota(context, value) {
        context.commit(SET_ROTA,value)
      }



};
const getters = {};

const mutations = {
    [SET_LISTA_EVENTO](state, value) {
        state.lista_eventos = value;
    },
    [SET_LISTA_EVENTO_REV](state, value) {
        state.lista_eventos = state.lista_eventos.filter(
            (eventos) => eventos.id !== value.id
        );
    },
    [SET_EVENTO](state, value) {
        state.evento = value;
    },
    [SET_ROTA](state,value){
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
      
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
        };
      },

};

const state = {
    lista_eventos: [],
    lista_eventos_Evento: [],
    evento:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
