
import ApiService from "@/core/services/api.service";


export const SET_LISTA_CATEGORIAS = "setListCategoria";
export const SET_LISTA_CATEGORIAS_REV = "setListCategoriaRev";
export const SET_CATEGORIA = "setListCategoriaRev";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'




const actions = {
    //evento

    async vinculo_categoria_evento(context, value) {

        await ApiService.post('categoria/evento/'+value.eventos_id, value.categorias)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    
    
    async vinculo_categoria_evento_site(context, value) {
        await ApiService.post('categoria/site/'+value.eventos_id, value.categorias)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },
    async create_categoria(context, value) {

        await ApiService.post('categoria', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async update_categoria(context, value) {

        await ApiService.put('categoria/'+value.id,value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_categorias(context) {
        await ApiService.get('categoria')
            .then(response => {
                context.commit(SET_LISTA_CATEGORIAS, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    async listar_categorias_site(context) {
        await ApiService.get('site/categorias')
            .then(response => {
                context.commit(SET_LISTA_CATEGORIAS, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async set_arquivo(context,value) {
        await ApiService.get('categoria/'+value)
            .then(response => {
                context.commit(SET_CATEGORIA, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async delete_categoria(context, value) {


        await ApiService.delete("evento/" + value)
          .then((response) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "error",
              message: error.response.data,
            })
          );
    
    
        context.commit(SET_LISTA_CATEGORIAS, value)
        context.commit(SET_LISTA_CATEGORIAS_REV, value)
    
    
      },

      set_rota(context, value) {
        context.commit(SET_ROTA,value)
      }



};
const getters = {};

const mutations = {
    [SET_LISTA_CATEGORIAS](state, value) {
        state.lista_categorias = value;
    },
    [SET_LISTA_CATEGORIAS_REV](state, value) {
        state.lista_categorias = state.lista_categorias.filter(
            (eventos) => eventos.id !== value.id
        );
    },
    [SET_CATEGORIA](state, value) {
        state.evento = value;
    },
    [SET_ROTA](state,value){
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
      
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
        };
      },

};

const state = {
    lista_categorias: [],
    lista_categorias_Evento: [],
    evento:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
