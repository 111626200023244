
import ApiService from "@/core/services/api.service";


export const SET_LISTA_PDVS_EVENTO = "setListPdvsEvento";
export const SET_LISTA_PDVS_EVENTO_REV = "setListPdvsEventoRev";
export const SET_PDVS_EVENTO = "setEventoRev";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'




const actions = {
    //evento
    async create_pdvs_evento(context, value) {

        await ApiService.post('pdvs_evento', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },



    // async create_pdvs_evento_site(context, value) {

    //     let message = await ApiService.post('pdvs_evento/produtor', value)
    //         .then(response => ({ tipo: 'success', message: response.data }))
    //         .catch((error) => ({ tipo: 'error', message: error.response.data }))
    //     context.commit(SET_MESSAGE_ALERT, message)
    //     context.commit(SET_PDVS_EVENTO, message.message.conteudo)
    // },

    async update_pdvs_evento(context, value) {

        await ApiService.put('pdvs_evento/'+value.id,value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_pdvs_eventos(context) {
        await ApiService.get('pdvs_evento')
            .then(response => {
                context.commit(SET_LISTA_PDVS_EVENTO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_pdvs_evento(context,value) {
        await ApiService.get('pdvs_evento/'+value)
            .then(response => {
                context.commit(SET_PDVS_EVENTO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },


    async delete_pdvs_evento(context, value) {


        await ApiService.delete("pdvs_evento/" + value.id)
          .then((response) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "error",
              message: error.response.data,
            })
          );
    
    
   
        context.commit(SET_LISTA_PDVS_EVENTO_REV, value)
    
    
      },

      set_rota(context, value) {
        context.commit(SET_ROTA,value)
      },
      async create_pdvs_evento_site(context, value) {

        await ApiService.put('evento/produtor/pdv/'+value.id, value.form)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },



};
const getters = {};

const mutations = {
    [SET_LISTA_PDVS_EVENTO](state, value) {
        state.lista_pdvs_eventos = value;
    },
    [SET_LISTA_PDVS_EVENTO_REV](state, value) {
        state.lista_pdvs_eventos = state.lista_pdvs_eventos.filter(
            (eventos) => eventos.id !== value.id
        );
    },
    [SET_PDVS_EVENTO](state, value) {
        state.pdvs_evento = value;
    },
    [SET_ROTA](state,value){
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
      
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
        };
      },

};

const state = {
    lista_pdvs_eventos: [],
    lista_pdvs_eventos_Evento: [],
    pdvs_evento:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
