
import ApiService from "@/core/services/api.service";


export const SET_LISTA_PDVS_EVENTO_INGRESSO = "setListPdvsEventoIngresso";
export const SET_LISTA_PDVS_EVENTO_INGRESSO_GRAFICOS = "setListPdvsEventoIngressoGraficos";

export const SET_LISTA_PDVS_EVENTO_INGRESSO_REV = "setListPdvsEventoIngressoRev";
export const SET_PDVS_EVENTO_INGRESSO = "setEventoRevIngresso";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'




const actions = {
    //evento
    async create_pdvs_evento_ingresso(context, value) {

        await ApiService.post('pdvs_evento_ingresso', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },



    async create_pdvs_evento_ingresso_site(context, value) {

        let message = await ApiService.post('pdvs_evento_ingresso/produtor', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
        context.commit(SET_PDVS_EVENTO_INGRESSO, message.message.conteudo)
    },

    async update_pdvs_evento_ingresso(context, value) {

        await ApiService.put('pdvs_evento_ingresso/'+value.id,value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_pdvs_evento_ingressos(context) {
        await ApiService.get('pdvs_evento_ingresso')
            .then(response => {
                context.commit(SET_LISTA_PDVS_EVENTO_INGRESSO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_pdvs_evento_ingresso(context,value) {
        await ApiService.get('pdvs_evento_ingresso/ingressos/'+value)
            .then(response => {
                context.commit(SET_PDVS_EVENTO_INGRESSO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },


    async delete_pdvs_evento_ingresso(context, value) {
        await ApiService.delete("pdvs_evento_ingresso/" + value.id)
          .then((response) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "error",
              message: error.response.data,
            })
          );
    
        context.commit(SET_LISTA_PDVS_EVENTO_INGRESSO_REV, value)
      },

      

      async listar_pdv_ingressos_grafico(context,id_evento) {
        await ApiService.get('graficos/'+id_evento)
            .then(response => {
                context.commit(SET_LISTA_PDVS_EVENTO_INGRESSO_GRAFICOS, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

      set_rota(context, value) {
        context.commit(SET_ROTA,value)
      }



};
const getters = {};

const mutations = {
    [SET_LISTA_PDVS_EVENTO_INGRESSO](state, value) {
        state.lista_pdvs_evento_ingressos = value;
    },
    [SET_LISTA_PDVS_EVENTO_INGRESSO_GRAFICOS](state, value) {
        state.lista_pdvs_evento_ingressos_graficos = value;
    },
    
    [SET_LISTA_PDVS_EVENTO_INGRESSO_REV](state, value) {
        state.lista_pdvs_evento_ingressos = state.lista_pdvs_evento_ingressos.filter(
            (eventos) => eventos.id !== value.id
        );
    },
    [SET_PDVS_EVENTO_INGRESSO](state, value) {
        state.pdvs_evento_ingresso = value;
    },
    [SET_ROTA](state,value){
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
        };
      },

};

const state = {
    lista_pdvs_evento_ingressos_graficos: [],
    lista_pdvs_evento_ingressos: [],
    lista_pdvs_evento_ingressos_Evento: [],
    pdvs_evento_ingresso:{},
    mensagem_alert: "",
    rota:"",
    

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
