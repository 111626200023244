import Vue from "vue";
import App from "./App.vue";
import router from "./core/router/router";
import store from "@/core/store";
import ApiService from "@/core/services/api.service";
// Global 3rd party plugins
import vuetify from "@/core/plugins/vuetify";
import VueConfirmDialog from 'vue-confirm-dialog'
import VueSimpleAlert from "vue-simple-alert";
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
import ClipboardJS from "clipboard";
import i18n from "@/core/plugins/vue-i18n";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "@/core/plugins/vuemask";
import VueViewer from 'v-viewer'
	
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.snow.css';
import './registerServiceWorker'

import Vue2Editor from "vue2-editor";
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
Vue.use(Vue2Editor);
	
Vue.use(VueQuillEditor);



Vue.use(VueViewer)
Vue.config.productionTip = false;
window.PerfectScrollbar = PerfectScrollbar;
window.ClipboardJS = ClipboardJS;
Vue.use(VueConfirmDialog);
// Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.use(VueSimpleAlert);
ApiService.init();

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount("#app");