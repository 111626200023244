
import ApiService from "@/core/services/api.service";


export const SET_LISTA_INGRESSO = "setListIngresso";
export const SET_LISTA_INGRESSO_REV = "setListIngressoRev";
export const SET_INGRESSO = "setIngresso";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'




const actions = {
    //evento
    async create_ingresso(context, value) {

        await ApiService.post('ingresso', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },




    async update_ingresso(context, value) {

        await ApiService.put('ingresso/'+value.id,value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_ingressos(context) {
        await ApiService.get('ingresso')
            .then(response => {
                context.commit(SET_LISTA_INGRESSO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    async bloquear_ingresso(context, value) {


        await ApiService.delete("ingresso/" + value.id)
          .then((response) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "error",
              message: error.response.data,
            })
          );
    
    
   
        context.commit(SET_LISTA_INGRESSO_REV, value)
    
    
      },

      set_rota(context, value) {
        context.commit(SET_ROTA,value)
      },

      async create_ingresso_evento_site(context, value) {

        await ApiService.put('evento/produtor/ingresso/'+value.id, value.form)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },
    async novo_lote(context, value) {

        await ApiService.post('ingresso/lote/'+value.id, value.form)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    

};
const getters = {};

const mutations = {
    [SET_LISTA_INGRESSO](state, value) {
        state.lista_ingressos = value;
    },
    [SET_LISTA_INGRESSO_REV](state, value) {
        state.lista_ingressos = state.lista_ingressos.filter(
            (ingresso) => ingresso.id !== value.id
        );
    },
    [SET_INGRESSO](state, value) {
        state.evento = value;
    },
    [SET_ROTA](state,value){
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
      
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
        };
      },

};

const state = {
    lista_ingressos: [],
    lista_ingressos_Evento: [],
    evento:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
