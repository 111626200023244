
import ApiService from "@/core/services/api.service";


export const SET_LISTA_VALIDACAO = "setListIngresso";
export const SET_LISTA_VALIDACAO_REV = "setListIngressoRev";
export const SET_VALIDACAO = "setIngresso";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'




const actions = {
    //evento
    async create_validacao(context, value) {

        await ApiService.post('validacao', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },




    async update_validacao(context, value) {

        await ApiService.put('validacao/'+value.id,value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_validacao(context,value) {
        await ApiService.get('validacao/'+value)
            .then(response => {
                context.commit(SET_LISTA_VALIDACAO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    async bloquear_validacao(context, value) {


        await ApiService.delete("validacao/" + value.id)
          .then((response) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "error",
              message: error.response.data,
            })
          );
    
    
   
        context.commit(SET_LISTA_VALIDACAO_REV, value)
    
    
      },

      set_rota(context, value) {
        context.commit(SET_ROTA,value)
      },

      async create_validacao_evento_site(context, value) {

        await ApiService.put('evento/produtor/validacao/'+value.id, value.form)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },
    async novo_lote(context, value) {

        await ApiService.post('validacao/lote/'+value.id, value.form)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    

};
const getters = {};

const mutations = {
    [SET_LISTA_VALIDACAO](state, value) {
        state.lista_validacao = value;
    },
    [SET_LISTA_VALIDACAO_REV](state, value) {
        state.lista_validacao = state.lista_validacao.filter(
            (validacao) => validacao.id !== value.id
        );
    },
    [SET_VALIDACAO](state, value) {
        state.evento = value;
    },
    [SET_ROTA](state,value){
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
      
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
        };
      },

};

const state = {
    lista_validacao: [],
    lista_validacao_Evento: [],
    evento:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
