import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";



////modulos sistema
import sistema from "./vuex/modulos/sistema";
import configFuncionario from "./vuex/modulos/configFuncionarios";
import permissoes from "./vuex/modulos/permissoes"
import configEmpresa from "./vuex/modulos/configEmpresa"
import configUsuarios from "./vuex/modulos/configUsuarios"
import documentos from "./vuex/modulos/documentos"
import evento from "./vuex/modulos/evento/evento"
import endereco from "./vuex/modulos/endereco/endereco"
import categoria from "./vuex/modulos/categoria"
import pdv from "./vuex/modulos/pdvs/pdv";
import ingressos from "./vuex/modulos/pdvs/ingressos";
import parceiros from "./vuex/modulos/parceiros";
import setor from "./vuex/modulos/setor";
import taxaEventos from "./vuex/modulos/taxaEventos";


import pdvsEvento from "./vuex/modulos/pdvs/pdvsEvento";
import servicos from "./vuex/modulos/orcamentario/servicos";
import pdvsEventoIngresso from "./vuex/modulos/pdvs/pdvsEventoIngresso";
import saldoTransacoes from   "./vuex/modulos/pdvs/saldoTransacoes";
import maquininha from "./vuex/modulos/pdvs/maquininha";
import faturas from "./vuex/modulos/faturas";
import beneficiado from "./vuex/modulos/beneficiado"; 
import validacao from "./vuex/modulos/validacao";
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        htmlClass,
        config,
        breadcrumbs,
        profile,
        sistema,
        configFuncionario,
        permissoes,
        configEmpresa,
        configUsuarios,
        documentos,
        evento,
        endereco,
        categoria,
        pdv,
        pdvsEvento,
        servicos,
        pdvsEventoIngresso,
        saldoTransacoes,
        ingressos,
        parceiros,
        setor,
        taxaEventos,
        maquininha,
        faturas,
        beneficiado,
        validacao


    }
});