
import ApiService from "@/core/services/api.service";


export const SET_LISTA_MAQUININHA = "setListPdv";
export const SET_LISTA_MAQUININHA_REV = "setListPdvRev";
export const SET_MAQUININHA = "setPdv";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'




const actions = {
    //evento
    async create_maquininha(context, value) {

        await ApiService.post('maquin', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },



    // async create_maquininha_site(context, value) {

    //     let message = await ApiService.post('maquin/produtor', value)
    //         .then(response => ({ tipo: 'success', message: response.data }))
    //         .catch((error) => ({ tipo: 'error', message: error.response.data }))
    //     context.commit(SET_MESSAGE_ALERT, message)
    //     context.commit(SET_MAQUININHA, message.message.conteudo)
    // },

    async update_maquininha(context, value) {

        await ApiService.put('maquin/'+value.id,value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_maquininha(context) {
        await ApiService.get('maquin')
            .then(response => {
                context.commit(SET_LISTA_MAQUININHA, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    async bloquear_maquininha(context, value) {


        await ApiService.delete("maquininha/" + value.id)
          .then((response) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "error",
              message: error.response.data,
            })
          );
    
    
   
        context.commit(SET_LISTA_MAQUININHA_REV, value)
    
    
      },

      set_rota(context, value) {
        context.commit(SET_ROTA,value)
      }



};
const getters = {};

const mutations = {
    [SET_LISTA_MAQUININHA](state, value) {
        state.lista_maquininhas = value;
    },
    [SET_LISTA_MAQUININHA_REV](state, value) {
        state.lista_maquininhas = state.lista_maquininhas.filter(
            (pdv) => pdv.id !== value.id
        );
    },
    [SET_MAQUININHA](state, value) {
        state.evento = value;
    },
    [SET_ROTA](state,value){
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

};

const state = {
    lista_maquininhas: [],
    lista_maquininhas_Evento: [],
    evento:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
