
import ApiService from "@/core/services/api.service";


export const SET_LISTA_BENEFICIADO = "setListIngresso";
export const SET_LISTA_BENEFICIADO_REV = "setListIngressoRev";
export const SET_BENEFICIADO = "setIngresso";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'




const actions = {
    //evento
    // async create_beneficiado(context, value) {

    //     await ApiService.post('beneficiado', value)
    //         .then(response => context.commit(SET_MESSAGE_ALERT, {
    //             tipo: 'success',
    //             message: response.data
    //         }))
    //         .catch((error) => context.commit(SET_MESSAGE_ALERT, {
    //             tipo: 'error',
    //             message: error.response.data
    //         }))
    // },




    async update_beneficiado(context, value) {

        await ApiService.put('cliente/beneficiado/'+value.id,{status:value.status})
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_beneficiados(context) {
        await ApiService.get('cliente/beneficiado')
            .then(response => {
                context.commit(SET_LISTA_BENEFICIADO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    async bloquear_beneficiado(context, value) {


        await ApiService.delete("beneficiado/" + value.id)
          .then((response) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "error",
              message: error.response.data,
            })
          );
    
    
   
        context.commit(SET_LISTA_BENEFICIADO_REV, value)
    
    
      },

      set_rota(context, value) {
        context.commit(SET_ROTA,value)
      },

      async create_beneficiado_evento_site(context, value) {

        await ApiService.put('evento/produtor/beneficiado/'+value.id, value.form)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },
    async novo_lote(context, value) {

        await ApiService.post('beneficiado/lote/'+value.id, value.form)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    

};
const getters = {};

const mutations = {
    [SET_LISTA_BENEFICIADO](state, value) {
        state.lista_beneficiados = value;
    },
    [SET_LISTA_BENEFICIADO_REV](state, value) {
        state.lista_beneficiados = state.lista_beneficiados.filter(
            (beneficiado) => beneficiado.id !== value.id
        );
    },
    [SET_BENEFICIADO](state, value) {
        state.evento = value;
    },
    [SET_ROTA](state,value){
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
      
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
        };
      },

};

const state = {
    lista_beneficiados: [],
    lista_beneficiados_Evento: [],
    evento:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
