import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/view/pages/dadosEmpresa.vue"),
                    meta: { title: 'Dashboard' }
                },


                {
                    path: "/createFuncionario",
                    name: "createFuncionario",
                    component: () => import("@/view/components/rh/createFuncionario.vue")
                },

                {
                    path: "/gerenFuncionarios",
                    name: "gerenFuncionarios",
                    component: () => import("@/view/components/rh/gerenFuncionario.vue")
                },
                {
                    path: "/permissoes",
                    name: "permissoes",
                    component: () => import("@/view/components/config/permissons/permissoes.vue")
                },
                {
                    path: "/createRule",
                    name: "createRule",
                    component: () => import("@/view/components/config/permissons/createRule")
                },



                /* ------------------------------ GERENCIAMENTO ----------------------------- */
                //#region GERENCIAMENTO

                ////EMPRESA////
                {
                    path: "/empresa",
                    name: "empresa",
                    component: () => import("@/view/components/gerenciamento/empresa/listaEmpresa.vue")
                },

                {
                    path: "/createEmpresa",
                    name: "createEmpresa",
                    component: () => import("@/view/components/gerenciamento/empresa/createEmpresa.vue")
                },

                ////FIM EMPRESA///
                //////FILIAL/////
                {
                    path: "/filial",
                    name: "filial",
                    component: () => import("@/view/components/gerenciamento/filial/listaFilial.vue")
                },
                {
                    path: "/createFilial",
                    name: "createFilial",
                    component: () => import("@/view/components/gerenciamento/filial/createFilial.vue")
                },
                ////FIM FILIAL///




             
                {
                    path: "/perfil",
                    name: "perfil",
                    component: () =>
                        import("@/view/components/config/perfil/listaPerfil.vue")
                },

                {
                    path: "/createPerfil",
                    name: "createPerfil",
                    component: () =>
                        import("@/view/components/config/perfil/createPerfil.vue")
                },
                {
                    path: "/createEvento",
                    name: "createEvento",
                    component: () =>
                        import("@/view/components/comercial/createEvento.vue")
                },
                {
                    path: "/evento",
                    name: "evento",
                    component: () =>
                        import("@/view/components/comercial/listaEvento.vue")
                },


                {
                    path: "/createCategoria",
                    name: "createCategoria",
                    component: () =>
                        import("@/view/components/categoria/createCategoria.vue")
                },

                {
                    path: "/categoria",
                    name: "categoria",
                    component: () =>
                        import("@/view/components/categoria/listaCategoria.vue")
                },

                
                {
                    path: "/createPdv",
                    name: "createPdv",
                    component: () =>
                        import("@/view/components/pdv/createPdv.vue")
                },

                {
                    path: "/pdv",
                    name: "pdv",
                    component: () =>
                        import("@/view/components/pdv/listaPdv.vue")
                },


                {
                    path: "/createPdvsEvento",
                    name: "createPdvsEvento",
                    component: () =>
                        import("@/view/components/pdvsEvento/createPdvsEvento.vue")
                },

                {
                    path: "/pdvsEvento",
                    name: "pdvsEvento",
                    component: () =>
                        import("@/view/components/pdvsEvento/listaPdvsEvento.vue")
                },
                
                {
                    path: "/incresosPdv/:pdvs_evento_id?",
                    name: "incresosPdv",
                    component: () =>
                        import("@/view/components/pdvsEvento/createIncresosPdv.vue")
                },

                {
                    path: "/createServicos",
                    name: "createServicos",
                    component: () =>
                        import("@/view/components/servicos/createServicos.vue")
                },

                {
                    path: "/servicos",
                    name: "servicos",
                    component: () =>
                        import("@/view/components/servicos/listaServicos.vue")
                },

                  
                {
                    path: "/createIngressos",
                    name: "createIngressos",
                    component: () =>
                        import("@/view/components/ingressos/createIngressos.vue")
                },

                {
                    path: "/ingressos",
                    name: "ingressos",
                    component: () =>
                        import("@/view/components/ingressos/listaIngressos.vue")
                },
                {
                    path: "/ingressos/:eventos_id?",
                    name: "ingressosE",
                    component: () =>
                        import("@/view/components/ingressos/listaIngressos.vue")
                },
                {
                    path: "/createIngressos/:eventos_id?",
                    name: "createIngressosE",
                    component: () =>
                        import("@/view/components/ingressos/createIngressos.vue")
                },

              

             

                           
                {
                    path: "/createParceiros",
                    name: "createParceiros",
                    component: () =>
                        import("@/view/components/parceiros/createParceiros.vue")
                },

                {
                    path: "/parceiros",
                    name: "parceiros",
                    component: () =>
                        import("@/view/components/parceiros/listaParceiros.vue")
                },

                                  
                {
                    path: "/createSetor",
                    name: "createSetor",
                    component: () =>
                        import("@/view/components/config/setor/createSetor.vue")
                },

                {
                    path: "/setor",
                    name: "setor",
                    component: () =>
                        import("@/view/components/config/setor/listaSetor.vue")
                },
                {
                    path: "/createTaxaEventos",
                    name: "createTaxaEventos",
                    component: () =>
                        import("@/view/components/comercial/TaxaEventos/createTaxaEventos.vue")
                },

                {
                    path: "/taxaEventos",
                    name: "taxaEventos",
                    component: () =>
                        import("@/view/components/comercial/TaxaEventos/listaTaxaEventos.vue")
                },
               
                {
                    path: "/createMaquininhas",
                    name: "createMaquininhas",
                    component: () =>
                        import("@/view/components/maquininhas/createMaquininhas.vue")
                },

                {
                    path: "/maquininhas",
                    name: "maquininhas",
                    component: () =>
                        import("@/view/components/maquininhas/listaMaquininhas.vue")
                },
                {
                    path: "/faturas",
                    name: "faturas",
                    component: () =>
                        import("@/view/components/fatura/listaFaturas.vue")
                },
                {
                    path: "/validacao",
                    name: "validacao",
                    component: () =>
                        import("@/view/components/fatura/listaValidacao.vue")
                },

                {
                    path: "/beneficiados",
                    name: "beneficiados",
                    component: () =>
                        import("@/view/components/beneficiado/listaBeneficiado.vue")
                },
            ]

        },
        {
            path: "/",
            component: () => import("@/view/pages/auth/login_pages/Login-1"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/login_pages/Login-1")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () =>
                        import("@/view/pages/auth/Login")
                },

            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue"),
        },

        {
            path: "/criarEvento",
            name: "criarEvento",
            component: () => import("@/view/components/comercial/createEventoSite.vue"),
        },
        {
            path: "/atualizarEvento/:eventos_id?",
            name: "atualizarEvento",
            component: () => import("@/view/components/comercial/createEventoSite.vue"),
        },
        {
            path: "https://boraticketstore.com.br/",
            name: "site",
           
        },
        // {
        //     path: "/comprovante",
        //     name: "comprovante",
        //     component: () =>
        //         import ("@/view/components/financeiro/comprovante.vue")
        // },

    ]
});