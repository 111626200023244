
import ApiService from "@/core/services/api.service";


export const SET_LISTA_CIDADES = "setListCidadesRev";
export const SET_LISTA_ESTADOS = "setListEstadosRev";


export const SET_MESSAGE_ALERT = 'setMensagem'




const actions = {
    //evento
    async listar_estados(context,value) {
        await ApiService.get(`estado/pesquisa/${value}`)

            .then(response => {
                context.commit(SET_LISTA_ESTADOS, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    

    async listar_cidades(context,value) {
        await ApiService.get(`cidade/pesquisa/${value}`)
            .then(response => {
                context.commit(SET_LISTA_CIDADES, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
 


};
const getters = {};

const mutations = {
    [SET_LISTA_CIDADES](state, value) {
        state.lista_cidades =  value.map(cidade => ({id: cidade.id, label: cidade.nome}))

    },
    [SET_LISTA_ESTADOS](state, value) {
        state.lista_estados =  value.map(estado => ({id: estado.id, label: estado.nome}))
    },

    [SET_MESSAGE_ALERT](state, value) {
      
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
        };
      },

};

const state = {
    lista_cidades: [],
    lista_estados: [],
    evento:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
