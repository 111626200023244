
import ApiService from "@/core/services/api.service";


export const SET_LISTA_PDV = "setListPdv";
export const SET_LISTA_PDV_REV = "setListPdvRev";
export const SET_PDV = "setPdv";
export const SET_ROTA = "setRota"
export const SET_MESSAGE_ALERT = 'setMensagem'




const actions = {
    //evento
    async create_pdv(context, value) {

        await ApiService.post('pdv', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },



    async create_pdv_site(context, value) {

        let message = await ApiService.post('pdv/produtor', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
        context.commit(SET_PDV, message.message.conteudo)
    },

    async update_pdv(context, value) {

        await ApiService.put('pdv/'+value.id,value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_pdvs(context) {
        await ApiService.get('pdv')
            .then(response => {
                context.commit(SET_LISTA_PDV, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },

    async bloquear_pdv(context, value) {


        await ApiService.delete("pdv/" + value.id)
          .then((response) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "error",
              message: error.response.data,
            })
          );
    
    
   
        context.commit(SET_LISTA_PDV_REV, value)
    
    
      },

      set_rota(context, value) {
        context.commit(SET_ROTA,value)
      }



};
const getters = {};

const mutations = {
    [SET_LISTA_PDV](state, value) {
        state.lista_pdvs = value;
    },
    [SET_LISTA_PDV_REV](state, value) {
        state.lista_pdvs = state.lista_pdvs.filter(
            (pdv) => pdv.id !== value.id
        );
    },
    [SET_PDV](state, value) {
        state.evento = value;
    },
    [SET_ROTA](state,value){
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
        console.log(value)
        let customMessage;
        if (typeof value.message === "object") {

            customMessage = value.message.msg;
        }
        state.mensagem_alert = {
            tipo: value.tipo,
            message: customMessage ? customMessage : value.message,
        };
    },

};

const state = {
    lista_pdvs: [],
    lista_pdvs_Evento: [],
    evento:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
