
import ApiService from "@/core/services/api.service";


export const SET_LISTA_SERVICOS = "setListServicosEvento";
export const SET_LISTA_SERVICOS_REV = "setListServicosEventoRev";
export const SET_SERVICO = "setServicosRev";
export const SET_ROTA = "setServicos"
export const SET_MESSAGE_ALERT = 'setMensagem'
export const SET_LISTA_TIPO_SERVICOS = 'setListTipoServicos'




const actions = {
    //evento
    async create_servicos(context, value) {

        await ApiService.post('servicos', value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },



    async create_servicos_site(context, value) {

        let message = await ApiService.post('servicos/produtor', value)
            .then(response => ({ tipo: 'success', message: response.data }))
            .catch((error) => ({ tipo: 'error', message: error.response.data }))
        context.commit(SET_MESSAGE_ALERT, message)
        context.commit(SET_SERVICO, message.message.conteudo)
    },

    async update_servicos(context, value) {

        await ApiService.put('servicos/'+value.id,value)
            .then(response => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'success',
                message: response.data
            }))
            .catch((error) => context.commit(SET_MESSAGE_ALERT, {
                tipo: 'error',
                message: error.response.data
            }))
    },

    async listar_servicos(context) {
        await ApiService.get('servicos')
            .then(response => {
                context.commit(SET_LISTA_SERVICOS, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_tipo_servicos(context) {
        await ApiService.get('servicos/tipo')
            .then(response => {
                context.commit(SET_LISTA_TIPO_SERVICOS, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },
    async listar_servico(context,value) {
        await ApiService.get('servicos/'+value)
            .then(response => {
                context.commit(SET_SERVICO, response.data)
            })
            .catch((error) => {
                context.commit(SET_MESSAGE_ALERT, {
                    tipo: 'error',
                    message: error.response.data
                })
            })
    },


    async delete_servicos(context, value) {


        await ApiService.delete("servicos/" + value.id)
          .then((response) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "success",
              message: response.data,
            })
          )
          .catch((error) =>
            context.commit(SET_MESSAGE_ALERT, {
              tipo: "error",
              message: error.response.data,
            })
          );
    
    
   
        context.commit(SET_LISTA_SERVICOS_REV, value)
    
    
      },

      set_rota(context, value) {
        context.commit(SET_ROTA,value)
      }



};
const getters = {};

const mutations = {
    [SET_LISTA_SERVICOS](state, value) {
        state.lista_servicos = value;
    },
    [SET_LISTA_SERVICOS_REV](state, value) {
        state.lista_servicos = state.lista_servicos.filter(
            (eventos) => eventos.id !== value.id
        );
    },
    [SET_LISTA_TIPO_SERVICOS](state,value){
        state.lista_tipo_eventos = value;

    },
    [SET_SERVICO](state, value) {
        state.servico = value;
    },
    [SET_ROTA](state,value){
        state.rota = value
    },
    [SET_MESSAGE_ALERT](state, value) {
      
        let customMessage;
        if (typeof value.message === "object") {
    
          customMessage = value.message.msg;
        }
        state.mensagem_alert = {
          tipo: value.tipo,
          message: customMessage ? customMessage : value.msg,
        };
      },

};

const state = {
    lista_tipo_eventos:[],
    lista_servicos: [],
    lista_servicos_Evento: [],
    servico:{},
    mensagem_alert: "",
    rota:"",

};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
};
